<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <v-row wrap>
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="pagoData"
              :loading="loading"
              loading-text="Consultando... favor espere"
              item-key="EmpleadoPagoHistoricoID"
              show-expand
              single-expand
              :expanded.sync="expanded"
            >
              <template v-slot:item.data-table-expand="{ expand, isExpanded }">
                <v-icon @click="expand(!isExpanded)">{{
                  isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_up"
                }}</v-icon>
              </template>

              <template v-slot:item.CodigoTarjeta="{ item }">{{
                getName(
                  listEmpleado,
                  item.EmpleadosId,
                  "EmpleadosId",
                  "CodigoTarjeta"
                )
              }}</template>
              <template v-slot:item.Empleado="{ item }">{{
                getName(listEmpleado, item.EmpleadosId, "EmpleadosId", "Nombre")
              }}</template>
              <template v-slot:item.Configuracion="{ item }">{{
                item.AsociadosComisionGroups.Nombre
              }}</template>
              <template v-slot:item.FechaProcesamiento="{ item }">{{
                item.FechaProcesamiento | date
              }}</template>
              <template v-slot:item.Variable="{ item }">{{
                item.Variable | numeric
              }}</template>
              <template v-slot:item.LogroTotal="{ item }">{{
                item.LogroTotal | numeric
              }}</template>
              <template v-slot:item.Incentivo="{ item }">{{
                item.Incentivo | numeric
              }}</template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  style="background: #f2f2f2; padding: 5px"
                >
                  <v-tabs>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab href="#incentivo">INCENTIVOS</v-tab>
                    <v-tab href="#credito-descuento"
                      >CREDITO Y DESCUENTOS</v-tab
                    >
                    <v-tab-item value="incentivo">
                      <div class="pa-4" style="background: #fff !important">
                        <v-data-table
                          :headers="headerIncentivo"
                          :items="item.EmpleadoPagoHistoricoIncentivos"
                        >
                          <template v-slot:item.Indicador="{ item }">{{
                            item.Incentivos.NombreAlternativo
                          }}</template>
                          <template v-slot:item.TipoCalculo="{ item }">{{
                            getTipoCalculo(item.Incentivos.AgrupacionCalculo)
                          }}</template>
                          <template v-slot:item.Resultado="{ item }">{{
                            item.Resultado | numeric
                          }}</template>
                          <template v-slot:item.Monto="{ item }">{{
                            item.Monto | numeric
                          }}</template>
                          <template v-slot:item.Action="{ item }">
                            <v-btn
                              text
                              color="primary"
                              depressed
                              @click="
                                setIndicador(item);
                                selectedIndicador = [];
                              "
                            >
                              Ver detalle
                            </v-btn>
                          </template>
                        </v-data-table>
                      </div>
                    </v-tab-item>
                    <v-tab-item value="credito-descuento">
                      <div class="pa-4" style="background: #fff !important">
                        <v-data-table
                          :headers="headerCreditoDescuento"
                          :items="item.EmpleadoPagoHistoricoCreditosDescuentos"
                        ></v-data-table>
                      </div>
                    </v-tab-item>
                  </v-tabs>
                  <br />
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-row wrap>
          <v-col cols="12">
            <v-autocomplete
              :items="listSegmento"
              v-model="selectedSegmento"
              item-text="Nombre"
              item-value="SegmentoID"
              label="Segmento"
              append-icon="keyboard_arrow_down"
              hint="Dejar vacio para filtrar por todos"
              persistent-hint
              clearable
              multiple
              chips
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              label="Tiendas"
              :items="listTienda"
              v-model="selectedTienda"
              item-text="Nombre"
              item-value="CanalSucursalId"
              append-icon="keyboard_arrow_down"
              hint="Dejar vacio para filtrar por todos"
              persistent-hint
              clearable
              multiple
              chips
              deletable-chips
            ></v-autocomplete>
          </v-col>
        </v-row>
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            depressed
            color="primary"
            :disabled="disabled"
            @click="buscar"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" persistent width="75%" he>
      <v-card>
        <v-tabs>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab href="#tab1">Indicadores</v-tab>
          <v-tab href="#tab2">Activaciones</v-tab>
          <v-tab-item value="tab1">
            <v-card-text>
              <v-data-table
                v-model="selectedIndicador"
                :headers="headerIndicador"
                :items="indicadorData"
                item-key="EmpleadoPagoHistoricoIndicadorID"
                show-expand
                single-expand
                hide-default-footer
              >
                <template v-slot:item.Indicador="{ item }">{{
                  item.IncentivoVariables.Nombre
                }}</template>
                <template v-slot:item.ObjetivoPonderado="{ item }">
                  <v-chip v-if="item.ObjetivoPonderado" color="info">Si</v-chip>
                  <v-chip
                    v-if="!item.ObjetivoPonderado"
                    color="red"
                    text-color="white"
                    >No</v-chip
                  >
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    style="background: #f2f2f2; padding: 5px"
                  >
                    <v-card id="detalle" tile outlined class="white pa-2">
                      <div v-if="!isCatalog">
                        <div class="title font-weight-light">
                          Resultado por familia
                        </div>
                        <v-simple-table fixed-header :height="250">
                          <thead>
                            <tr>
                              <th class="text-left">FAMILIA</th>
                              <th class="text-left">OBJETIVO MAX</th>
                              <th class="text-left">OBJETIVO</th>
                              <th class="text-left">OBJETIVO MIN</th>
                              <th class="text-right">RESULTADO</th>
                              <th class="text-right">LOGRO</th>
                              <th class="text-right">LOGRO REAL</th>
                              <th class="text-right">PESO</th>
                              <th class="text-left">MANUAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="objetivo in item.EmpleadoPagoHistoricoIndicadorObjetivoFamilia"
                              :key="
                                objetivo.EmpleadoPagoHistoricoIndicadorObjetivoFamiliaID
                              "
                            >
                              <td>{{ objetivo.ProductoTipos.ProductoName }}</td>
                              <td class="text-right">
                                {{ objetivo.ObjetivoMaximo | numeric }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.ObjetivoMedio | numeric }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.ObjetivoMinimo | numeric }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.Resultado | numeric }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.PorcentajeAlcanzado }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.PorcentajeAlcanzadoReal }}
                              </td>
                              <td class="text-right">
                                {{ objetivo.Peso | numeric }}
                              </td>
                              <td>
                                <v-chip v-if="objetivo.Manual" color="info"
                                  >Si</v-chip
                                >
                                <v-chip
                                  v-if="!objetivo.Manual"
                                  color="red"
                                  text-color="white"
                                  >No</v-chip
                                >
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                      <div v-if="isCatalog">
                        <div class="title font-weight-light">
                          Reglas catálogo
                        </div>
                        <v-simple-table fixed-header :height="300">
                          <thead>
                            <tr>
                              <th class="text-left">REGLA</th>
                              <th class="text-left">A CUMPLIR</th>
                              <th class="text-left">CUMPLIO</th>
                              <th class="text-left">LOGRO MIN</th>
                              <th class="text-right">LOGRO ALCANZADO</th>
                              <th class="text-right">CUMPLIDA</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="indicador in item.EmpleadoPagoHistoricoIndicadorRegla"
                              :key="
                                indicador.EmpleadoPagoHistoricoIndicadorReglaID
                              "
                            >
                              <td>{{ indicador.Nombre }}</td>
                              <td class="text-right">
                                {{ indicador.FamiliasACumplir | numeric }}
                              </td>
                              <td class="text-right">
                                {{ indicador.FamiliasCumplio | numeric }}
                              </td>
                              <td class="text-right">
                                {{ indicador.LogroMin | numeric }}
                              </td>
                              <td class="text-right">
                                {{ indicador.LogroAlcanzo | numeric }}
                              </td>
                              <td>
                                <v-chip
                                  v-if="indicador.ReglaCumplida"
                                  color="info"
                                  >Si</v-chip
                                >
                                <v-chip
                                  v-if="!indicador.ReglaCumplida"
                                  color="red"
                                  text-color="white"
                                  >No</v-chip
                                >
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                    </v-card>
                    <br />
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item value="tab2">
            <v-card-text>
              <v-simple-table fixed-header :height="250">
                <thead>
                  <tr>
                    <th class="text-left">TELEFONO</th>
                    <th class="text-left">FECHA ACTIVACION</th>
                    <th class="text-left">FAMILIA</th>
                    <th class="text-left">CODIGO PRODUCTO</th>
                    <th class="text-left">PRODUCTO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in listIncentivoActivacion"
                    :key="item.ActivationID"
                  >
                    <td>{{ item.Telefono }}</td>
                    <td>{{ item.FechaActivacion | date }}</td>
                    <td>{{ item.Familia }}</td>
                    <td>{{ item.ProductCode }}</td>
                    <td>{{ item.ProductName }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <download-csv
                class="
                  mr-1
                  v-btn v-btn-- v-btn--text
                  theme--light
                  v-size--default
                  exportar
                "
                :labels="headersActivacionIncentivo"
                :fields="fields"
                :data="listIncentivoActivacion"
                name="resultado activacion incentivo.csv"
              >
                Exportar
              </download-csv>
              <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import apiComision from "@/api/comisiones";
import helper from "@/filters/helper";
import download from "downloadjs";

export default {
  data() {
    return {
      drawerRight: true,
      disabled: true,
      tipo: "individual",
      filter: true,
      loading: false,
      dialog: false,
      selectedSegmento: [],
      selectedTienda: [],
      selectedFamilia: [],
      selectedEstado: [],
      selectedProducto: [],
      selectedIndicador: [],
      listTienda: [],
      listProducto: [],
      listEstado: [],
      listIncentivoActivacion: [],
      file: [],
      pagoData: [],
      indicadorData: [],
      expanded: [],
      headers: [
        { text: "TARJETA", value: "CodigoTarjeta" },
        { text: "EMPLEADO", value: "Empleado" },
        { text: "CONFIGURACION", value: "Configuracion" },
        { text: "FECHA", value: "FechaProcesamiento" },
        { text: "NORMA SALARIO", value: "Variable" },
        { text: "LOGRO", value: "LogroTotal" },
        { text: "SALARIO VARIABLE", value: "Incentivo" },
      ],
      headerIndicador: [
        { text: "INDICADOR", value: "Indicador" },
        { text: "PONDERADO", value: "ObjetivoPonderado" },
        { text: "PAGO MIN", value: "MinPago" },
        { text: "PAGO MAX", value: "MaxPago" },
        { text: "OBJETIVO MIN", value: "ObjetivoMin" },
        { text: "OBJETIVO", value: "Objetivo" },
        { text: "OBJETIVO MAX", value: "ObjetivoMax" },
      ],
      headerIncentivo: [
        { text: "INDICADOR", value: "Indicador" },
        { text: "TIPO CALCULO", value: "TipoCalculo" },
        { text: "PESO", value: "Peso" },
        { text: "OBJETIVO", value: "Objetivo" },
        { text: "RESULTADO", value: "Resultado" },
        { text: "LOGRO", value: "PorcentajeAlcanzadoReal" },
        { text: "MONTO", value: "Monto" },
        { text: "", value: "Action" },
      ],
      headerCreditoDescuento: [
        { text: "CONCEPTO", value: "Concepto" },
        { text: "MONTO", value: "Monto" },
      ],
      headersActivacionIncentivo: [
        { text: "TELEFONO", value: "Telefono" },
        { text: "FECHA ACTIVACION", value: "FechaActivacion" },
        { text: "FAMILIA", value: "Familia" },
        { text: "CODIGO PRODUCTO", value: "ProductCode" },
        { text: "PRODUCTO", value: "ProductName" },
      ],
      fields: [
        "Telefono",
        "FechaActivacion",
        "Familia",
        "ProductCode",
        "ProductName",
      ],
      breadcrumb: [
        {
          text: "Comisiones",
          disabled: true,
        },
      ],
      incentivos: [],
    };
  },
  computed: {
    listSegmento() {
      return this.$store.getters.user.segmento;
    },
    listFamilia() {
      return this.$store.getters.tipoProducto;
    },
    listEmpleado() {
      return this.$store.getters.user.seleccion.empleados;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    isCatalog() {
      if (this.incentivos.Incentivos) {
        return this.incentivos.Incentivos.TipoConfiguracion == 4;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    buscar() {
      this.loading = true;
      var param = {
        corte: this.currentUser.corte,
        empleado: Array.from(
          this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
        ),
        segmento: Array.from(this.selectedSegmento),
        sucursal: Array.from(this.selectedTienda),
        canal: Array.from(this.currentUser.seleccion.canales),
      };
      apiComision
        .getComisionEmpleado(param)
        .then((response) => {
          this.pagoData = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.setError(error);
          this.loading = false;
        });
    },
    getIncentivoActivacion(empleado, incentivo, comisionar) {
      let param = {
        corte: this.currentUser.corte,
        empleadoID: empleado,
        incentivoID: incentivo,
        comisionar: comisionar,
      };

      this.listIncentivoActivacion = [];
      apiComision
        .getIncentivoActivacion(param)
        .then((response) => {
          this.listIncentivoActivacion = response.data;
        })
        .catch((error) => {
          this.setError(error);
          this.loading = false;
        });
    },
    getName(list, id, columnId, columnName) {
      return helper.capitalCase(
        helper.abbreviateName(helper.getName(list, id, columnId, columnName))
      );
    },
    getTipoCalculo(value) {
      return helper.tipoCalculo.find((c) => (c.value = value)).text;
    },
    setIndicador(item) {
      var historico = this.pagoData.find(
        (c) => c.EmpleadoPagoHistoricoID == item.EmpleadoPagoHistoricoID
      );
      this.indicadorData = item.EmpleadoPagoHistoricoIndicadores;
      this.incentivos = item;
      this.getIncentivoActivacion(
        historico.EmpleadosId,
        item.IncentivoID,
        item.Incentivos.Comisionar
      );
      this.dialog = true;
    },
    sum(list, property) {
      return list.reduce((a, b) => a + (b[property] || 0), 0);
    },
    download() {
      download(
        this.listIncentivoActivacion,
        "activaciones_incentivos" + ".xlsx"
      );
    },
  },
  watch: {
    selectedSegmento: function () {
      this.listTienda = [];
      var list = this.listSegmento
        .filter((c) => this.selectedSegmento.includes(c.SegmentoID))
        .map((c) => c.CanalAsociadoSucursales);

      list.forEach((c) => {
        if (c.length > 0) {
          c.forEach((d) => {
            this.listTienda.push(d);
          });
        }
      });
    },
  '$store.state.updateCorte':function(){
     this.buscar();
  }
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    if (this.currentUser.seleccion == undefined) {
      this.disabled = true;
      this.setMessage(
        "Primero debe seleccionar el corte para navegar en el sistema"
      );
    } else {
      this.disabled = false;
      this.buscar();
    }
  },


};
</script>
<style>
tr.expanded.expanded__content {
  box-shadow: none !important;
}
</style>